$(document).ready(function () {
  // Insert global code here
  // consider creating other global files in this folder

  removeEmptyParagraphInsideHtmlArea()
});

function removeEmptyParagraphInsideHtmlArea () {
  $('.htmlArea p, .glossary-list .glossary-list__text p').each(function () {
    const $this = $(this);

    if ($this.html().replace(/<p><\/p>/g, '').length == 0) {
      $this.remove();
    }

  });
}


function removeUnusedParagraphInsideHtmlArea () {
  $('.htmlArea p, .glossary-list .glossary-list__text p').each(function () {
    const $this = $(this);
    if ($this.html().replace(/\s|&nbsp;/g, '').length == 0) {
      $this.remove();
    }
  });
}

/**
 * scrolls page to selected element
 * @param {*} element
 * @param {Number} h height the page should scroll to the element.
 */
 function goToScrollTop (element, h = 20) {
  if (element.length) {
    $('html, body').animate(
      {
        scrollTop: element.offset().top - h
      }, 700)
  }
}
